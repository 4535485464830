// Generated by Framer (04e0834)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, SVG, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {TfN45rBIF: {hover: true}};

const cycleOrder = ["b0WvOJjZ3", "TfN45rBIF"];

const serializationHash = "framer-T90Bm"

const variantClassNames = {b0WvOJjZ3: "framer-v-okzqj5", TfN45rBIF: "framer-v-dgg5e6"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Ativado: "b0WvOJjZ3", Desativado: "TfN45rBIF"}

const getProps = ({click, height, id, width, ...props}) => { return {...props, M9xsMqoqs: click ?? props.M9xsMqoqs, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "b0WvOJjZ3"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, M9xsMqoqs, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "b0WvOJjZ3", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap7x860y = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (M9xsMqoqs) {const res = await M9xsMqoqs(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-okzqj5", className, classNames)} data-framer-name={"Ativado"} data-highlight layoutDependency={layoutDependency} layoutId={"b0WvOJjZ3"} onTap={onTap7x860y} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"TfN45rBIF-hover": {"data-framer-name": undefined}, TfN45rBIF: {"data-framer-name": "Desativado"}}, baseVariant, gestureVariant)}><SVG className={"framer-1wtrc0f"} data-framer-name={"BRA"} fill={"black"} intrinsicHeight={32} intrinsicWidth={32} layoutDependency={layoutDependency} layoutId={"bFqU3iPqx"} style={{opacity: 1}} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"32\" height=\"32\"><rect x=\"1\" y=\"4\" width=\"30\" height=\"24\" rx=\"4\" ry=\"4\" fill=\"#459a45\"/><path d=\"M27 4H5a4 4 0 0 0-4 4v16a4 4 0 0 0 4 4h22a4 4 0 0 0 4-4V8a4 4 0 0 0-4-4Zm3 20c0 1.654-1.346 3-3 3H5c-1.654 0-3-1.346-3-3V8c0-1.654 1.346-3 3-3h22c1.654 0 3 1.346 3 3v16Z\" opacity=\".15\"/><path d=\"M3.472 16 16 24l12.528-8L16 8 3.472 16Z\" fill=\"#fedf00\"/><circle cx=\"16\" cy=\"16\" r=\"5\" fill=\"#0a2172\"/><path d=\"M14 14.5c-.997 0-1.958.149-2.873.409-.078.35-.126.71-.127 1.083a9.456 9.456 0 0 1 2.999-.493c2.524 0 4.816.996 6.519 2.608a4.98 4.98 0 0 0 .356-1.026 10.443 10.443 0 0 0-6.875-2.583Z\" fill=\"#fff\"/><path d=\"M27 5H5a3 3 0 0 0-3 3v1a3 3 0 0 1 3-3h22a3 3 0 0 1 3 3V8a3 3 0 0 0-3-3Z\" fill=\"#fff\" opacity=\".2\"/></svg>"} variants={{"TfN45rBIF-hover": {opacity: 1}, TfN45rBIF: {opacity: 0.5}}} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-T90Bm.framer-3r6ail, .framer-T90Bm .framer-3r6ail { display: block; }", ".framer-T90Bm.framer-okzqj5 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-T90Bm .framer-1wtrc0f { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 32px); position: relative; width: 32px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-T90Bm.framer-okzqj5 { gap: 0px; } .framer-T90Bm.framer-okzqj5 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-T90Bm.framer-okzqj5 > :first-child { margin-left: 0px; } .framer-T90Bm.framer-okzqj5 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"TfN45rBIF":{"layout":["auto","auto"]},"DFqFhQjiT":{"layout":["auto","auto"]}}}
 * @framerVariables {"M9xsMqoqs":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerBbICwetrN: React.ComponentType<Props> = withCSS(Component, css, "framer-T90Bm") as typeof Component;
export default FramerBbICwetrN;

FramerBbICwetrN.displayName = "BRA Icon";

FramerBbICwetrN.defaultProps = {height: 32, width: 32};

addPropertyControls(FramerBbICwetrN, {variant: {options: ["b0WvOJjZ3", "TfN45rBIF"], optionTitles: ["Ativado", "Desativado"], title: "Variant", type: ControlType.Enum}, M9xsMqoqs: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerBbICwetrN, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})